// All pages
document.addEventListener('DOMContentLoaded', function(){

    //
    // For header
    //

    // Text Writer
    var app = document.getElementById('textWriter'),
        text = app.innerHTML;

    var typewriter = new Typewriter(app, {
        loop: true
    });

    typewriter
        .typeString(text)
        .pauseFor(2000)
        .deleteAll()
        .start();

    // Sticky menu
    var header          = document.querySelector('.h'),
        headerMid       = document.querySelector('.h__ml'),
        headerTopHeight = document.querySelector('.h__bl-text').scrollHeight,
        headerMidHeight = headerMid.scrollHeight;

    window.addEventListener('scroll', function(){

        if (window.pageYOffset > headerTopHeight ) {
            header.classList.add('h--sticky');
        } else {
            header.classList.remove('h--sticky');
        }
    });

    //
    // For footer
    //
    var subscribeForm = document.querySelector('.f__subscribe'),
        subscribeBtn = document.querySelector('.f__subscribe-btn');
    subscribeForm.addEventListener('submit', function(e){
        e.preventDefault();

        var oldMsg = document.querySelector('.f__subscribe-result');
        if (oldMsg){
            oldMsg.remove();
        }

        var request = new XMLHttpRequest();
        request.open(ajaxMethod, ajaxUrl+'data/subscribe.json', true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');


        request.onreadystatechange = function() {
            if (this.readyState != 4) return;

            var resp = JSON.parse(this.response),
                p = document.createElement('p');
            p.classList.add('f__subscribe-result');
            p.innerHTML = resp.msg;

            if (resp.err){
                p.classList.add('f__subscribe-result--error');
            } else {
                p.classList.add('f__subscribe-result--success');
            }

            subscribeForm.append(p);
            subscribeBtn.disabled = false;
        }

        request.onerror = function() {
            console.log('.f__subscribe-btn.click():connection error');
            subscribeBtn.disabled = false;
        };

        var emailInput = document.querySelector('.f__subscribe-input'),
            data = emailInput.getAttribute('name') + '=' + encodeURIComponent(emailInput.value);

        subscribeBtn.disabled = true;
        request.send(data);
    });

});

// Color change on catalog
document.addEventListener('DOMContentLoaded', function(){

    var container = document.querySelector('.products__container');
    if (!container){
        return;
    }

    // Color change
    container.addEventListener('click', function(e){
        var btn = e.target;

        if (!btn.classList.contains('pc__color')){
            return;
        }

        btn = btn.closest('.pc__item');

        var item = btn.closest('.products__item'),
            img = item.querySelector('.product__photo');
        item.querySelector('.pc__item--active').classList.remove('pc__item--active');
        btn.classList.add('pc__item--active');
        img.setAttribute('src', btn.dataset.src);
    });
});

// Catalog
document.addEventListener('DOMContentLoaded', function(){

    var loadMoreBtn = document.querySelector('.project-load-more .btn');
    if (!loadMoreBtn){
        return;
    }

    var container = document.querySelector('.products__container');
    loadMoreBtn.addEventListener('click', function(){

        var request = new XMLHttpRequest();
        request.open(ajaxMethod, ajaxUrl+'data/loadMoreItems.json', true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');


        request.onreadystatechange = function() {
            if (this.readyState != 4) return;

            var resp = JSON.parse(this.response);

            container.insertAdjacentHTML('beforeend', resp.itemsHtml);

            if (resp.isLast){
                loadMoreBtn.remove();
            } else {
                loadMoreBtn.disabled = false;
                loadMoreBtn.dataset.page = parseInt(loadMoreBtn.dataset.page) + 1;
            }
        }

        request.onerror = function() {
            console.log('.project-load-more .btn.click():connection error');
            loadMoreBtn.disabled = false;
        };

        var data = 'page=' + loadMoreBtn.dataset.page;

        loadMoreBtn.disabled = true;
        request.send(data);
    });

});

// Product page
document.addEventListener('DOMContentLoaded', function(){

    var sliderThumbs = document.querySelector('.product-card__gallery-thumbs');
    if (!sliderThumbs){
        return;
    }

    var galleryThumbs  = new Swiper(sliderThumbs,{
        direction: 'vertical',
        slidesPerView: 4,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        navigation: {
            nextEl: sliderThumbs.querySelector('.slider-button--next'),
            prevEl: sliderThumbs.querySelector('.slider-button--prev')
        }
    });

    var galleryMain = document.querySelector('.product-card__gallery-main');
    var galleryTop = new Swiper(galleryMain, {
        navigation: {
            nextEl: galleryMain.querySelector('.slider-button--next'),
            prevEl: galleryMain.querySelector('.slider-button--prev')
        },
        thumbs: {
            swiper: galleryThumbs
        }
    });

    // color change
    var colorBtns = document.querySelectorAll('.pd .pc__item'),
        colorsWrp = document.querySelector('.pd__colors');
    colorBtns.forEach(function(btn){
        btn.addEventListener('click', function(){
            sliderThumbs.querySelector('.swiper-slide:first-child img').setAttribute('src', btn.dataset.srcPreview);
            galleryMain.querySelector('.swiper-slide:first-child img').setAttribute('src', btn.dataset.srcMain);
            galleryTop.slideTo(0);

            colorsWrp.querySelector('.pc__item--active').classList.remove('pc__item--active');
            btn.classList.add('pc__item--active');
        });
    });

    // add to cart
    var addBtn = document.querySelector('.pd__add-btn'),
        form = document.querySelector('.pd'),
        panel = document.querySelector('.cart-panel'),
        itemsList = panel.querySelector('.cart-panel__list'),
        priceEl = panel.querySelector('#price'),
        shippingEl = panel.querySelector('#shipping'),
        overlay = document.querySelector('.overlay');
    addBtn.addEventListener('click', function(){

        var request = new XMLHttpRequest();
        request.open(ajaxMethod, ajaxUrl+'data/addToCart.json', true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');


        request.onreadystatechange = function() {
            if (this.readyState != 4) return;

            var resp = JSON.parse(this.response);
            itemsList.innerHTML = '';
            itemsList.insertAdjacentHTML('beforeend', resp.cartItems);
            priceEl.innerHTML = resp.price;
            shippingEl.innerHTML = resp.shipping;

            overlay.style.display = 'block';
            panel.classList.add('cart-panel--active');
            document.querySelector('.h__basket-icon').innerHTML = resp.itemsCount;

            addBtn.disabled = false;
        }

        request.onerror = function() {
            console.log('.pd__add-btn.click():connection error');
            addBtn.disabled = false;
        };

        var inputs = form.querySelectorAll('input'),
            data = '';
        inputs.forEach(function(input, index){
            if ( (input.getAttribute('type') == 'radio') && !input.checked ){
                return;
            }
            if (input.getAttribute('name') == 'email'){
                return;
            }
            if (index){
                data += '&'
            }
            data += input.getAttribute('name')+'='+input.value;
        });

        addBtn.disabled = true;
        request.send(data);
    });

    var closeBtn = panel.querySelector('.cart-panel__close-btn');
    closeBtn.addEventListener('click', function(){
        overlay.style.display = '';
        panel.classList.remove('cart-panel--active');
    });

    // wait for inform
    var informBtn = document.querySelector('.pi__btn');
    informBtn.addEventListener('click', function(){

        var _email = form.querySelector('input[name="email"]'),
            emailVal = _email.value;

        if ( !emailVal || (emailVal && !emailVal.match(/.+@.+\..+/i)) ){
            _email.classList.add('input--error');
            return;
        }

        var request = new XMLHttpRequest();
        request.open(ajaxMethod, ajaxUrl+'data/wait4Inform.json', true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');


        request.onreadystatechange = function() {
            if (this.readyState != 4) return;

            var resp = JSON.parse(this.response);
            
            if (!resp.err){
                informBtn.style.display = 'none';
            } else {
                informBtn.disabled = false;
            }

            var p = document.createElement('p');
            p.classList.add('pi__result-text');
            p.innerHTML = resp.msg;

            document.querySelector('.pd__inform').append(p);
        }

        request.onerror = function() {
            console.log('.pi__btn.click():connection error');
            informBtn.disabled = false;
        };

        var inputs = form.querySelectorAll('input[name="id"], input[name="color"]:checked, input[name="email"]'),
            data = '';
        inputs.forEach(function(input, index){
            if (index){
                data += '&'
            }
            data += input.getAttribute('name')+'='+input.value;
        });
        informBtn.disabled = true;
        request.send(data);
    });
})

// preview gallery
document.addEventListener('DOMContentLoaded', function(){

    var sliderPreview = document.querySelector('.products__container.swiper-container');
    if (!sliderPreview){
        return;
    }

    var productWrp = sliderPreview.closest('.products');
    var galleryPreview = new Swiper(sliderPreview, {
        slidesPerView: 4,
        spaceBetween: 0,
        navigation: {
            nextEl: productWrp.querySelector('.slider-button--next'),
            prevEl: productWrp.querySelector('.slider-button--prev')
        }
    });

})

// tabs
document.addEventListener('DOMContentLoaded', function(){

    var tabsHeader = document.querySelectorAll('.tabs__header-link');
    if (!tabsHeader){
        return;
    }

    tabsHeader.forEach(function(tab){

        tab.addEventListener('click', function(){
            document.querySelector('.tabs__header-link--active').classList.remove('tabs__header-link--active');
            this.classList.add('tabs__header-link--active');

            var target = this.dataset.target;
            document.querySelector('.tabs__content--active').classList.remove('tabs__content--active');
            document.getElementById(target).classList.add('tabs__content--active');
        });
    });

})

// quantity change
document.addEventListener('DOMContentLoaded', function(){

    var quantityBtns = document.querySelectorAll('.qnt__btn');
    if (!quantityBtns.length){
        return;
    }

    document.addEventListener('click', function(e){

        if (!e.target.classList.contains('qnt__btn')){
            return;
        }
        var btn = e.target;

        var quantityEl = btn.closest('.qnt').querySelector('.qnt__input');
        if (btn.classList.contains('qnt__btn--more')){
            if (quantityEl.value != quantityEl.getAttribute('max')){
                quantityEl.value = parseInt(quantityEl.value) + 1;
            }
        } else {
            if (quantityEl.value != quantityEl.getAttribute('min')){
                quantityEl.value = parseInt(quantityEl.value) - 1;
            }
        }
    });

    var qntInputs = document.querySelectorAll('.qnt__input');

    document.addEventListener('keyup', function(e){

        if (!e.target.classList.contains('qnt__input')){
            return;
        }
        var _input = e.target;

        if (!_input.value){
            _input.value = _input.getAttribute('min');
        } else {

            if ( parseInt(_input.value) < parseInt(_input.getAttribute('min')) ){
                _input.value = _input.getAttribute('min');
            }
            if ( parseInt(_input.value) > parseInt(_input.getAttribute('max')) ){
                _input.value = _input.getAttribute('max');
            }
        }

    });
})

// cart page
document.addEventListener('DOMContentLoaded', function(){

    // var delBtns = document.querySelectorAll('.order__item-del-btn');
    var orderBody = document.querySelector('.order__body');
    if (!orderBody){
        return;
    }

    var submitBtn = document.querySelector('.order__submit'),
        updateBtn = document.querySelector('.order__update'),
        popup = document.querySelector('.popup'),
        popupBtn = popup.querySelector('.popup__close-btn');

    // orderBody.addEventListener('click', function(e){
    //     if (!e.target.classList.contains('order__item-del-btn')){
    //         return;
    //     }
    //     var btn = e.target;
    //
    //     var request = new XMLHttpRequest();
    //     request.open(ajaxMethod, ajaxUrl+'data/removeFromCart.json', true);
    //     request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');
    //
    //
    //     request.onreadystatechange = function() {
    //         if (this.readyState != 4) return;
    //
    //         var resp = JSON.parse(this.response);
    //
    //         if (!resp.err){
    //             btn.closest('.order__line').remove();
    //             document.getElementById('totalPrice').innerHTML = resp.price;
    //             document.getElementById('delivery').innerHTML = resp.shipping;
    //             document.querySelector('.h__basket-icon').innerHTML = resp.itemsCount;
    //
    //             if (!resp.price){
    //                 submitBtn.disabled = true;
    //                 updateBtn.disabled = true;
    //             }
    //         } else {
    //             btn.disabled = false;
    //             alert(resp.msg);
    //         }
    //     }
    //
    //     request.onerror = function() {
    //         console.log('delBtns.click():connection error');
    //         btn.disabled = false;
    //     };
    //
    //     var data = 'id=' + btn.dataset.id;
    //
    //     btn.disabled = true;
    //     request.send(data);
    // });


    updateBtn.addEventListener('click', function(){

        var request = new XMLHttpRequest();
        request.open(ajaxMethod, ajaxUrl+'data/updateCart.json', true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');


        request.onreadystatechange = function() {
            if (this.readyState != 4) return;

            var resp = JSON.parse(this.response);

            if (!resp.err){
                orderBody.innerHTML = '';
                orderBody.insertAdjacentHTML('beforeend', resp.cartItems);
                document.getElementById('totalPrice').innerHTML = resp.price;
                document.getElementById('delivery').innerHTML = resp.shipping;
                document.querySelector('.h__basket-icon').innerHTML = resp.itemsCount;
            } else {
                alert(resp.msg);
            }

            updateBtn.disabled = false;
        }

        request.onerror = function() {
            console.log('updateBtn.click():connection error');
            updateBtn.disabled = false;
        };

        var data = '';
        document.querySelectorAll('.qnt__input').forEach(function(input, index){
            if (index){
                data += '&';
            }

            data += 'id[]=' + input.dataset.id;
            data += '&qnt[]=' + input.value;
        });

        updateBtn.disabled = true;
        request.send(data);
    });

    var _name = document.querySelector('input[name="fio"]'),
        _tel = document.querySelector('input[name="tel"]'),
        _email = document.querySelector('input[name="email"]'),
        telComplete = false;

    var mask = IMask(_tel, {
        mask: '+{7}(000)000-00-00'
    }).on('accept', function() {
        telComplete = false;
    }).on('complete', function() {
        telComplete = true;
    });

    submitBtn.addEventListener('click', function(){

        var err = document.querySelector('.input--error');
        if (err){
            err.classList.remove('input--error');
        }

        if (!_name.value){
            _name.classList.add('input--error');
            return;
        }

        if (!telComplete){
            _tel.classList.add('input--error');
            return;
        }

        var emailVal = _email.value;
        if ( !emailVal || (emailVal && !emailVal.match(/.+@.+\..+/i)) ){
            _email.classList.add('input--error');
            return;
        }

        var request = new XMLHttpRequest();
        request.open(ajaxMethod, ajaxUrl+'data/checkout.json', true);
        request.setRequestHeader('Content-type', 'application/x-www-form-urlencoded; charset=utf-8');


        request.onreadystatechange = function() {
            if (this.readyState != 4) return;

            var resp = JSON.parse(this.response);

            if (!resp.err){
                popup.style.display='';
            } else {
                alert(resp.msg);
            }

            updateBtn.disabled = false;
        }

        request.onerror = function() {
            console.log('submitBtn.click():connection error');
            submitBtn.disabled = false;
        };

        var data = '';
        document.querySelectorAll('.orderfield').forEach(function(input, index){
            if (index){
                data += '&';
            }
            data += input.getAttribute('name') + '=' + input.value  ;
        });
        // alert(data);

        submitBtn.disabled = true;
        request.send(data);
    });

    popup.addEventListener('click', function(e){

        if (e.target.classList.contains('popup')){
            window.location.href = popup.dataset.url;
        }
    });

    popupBtn.addEventListener('click', function(){

        window.location.href = popup.dataset.url;

    });
});